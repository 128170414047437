import React, { useEffect } from 'react';
import MainSlider from 'components/generals/main-slider';
import { useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
export default function PreloadPage() {
    const { pathname } = useLocation();
    const { t } = useTranslation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return (
        <>
            <main className="main__page main__page--full nv__preload">
                <MainSlider logoSlogan={false} />
            </main>
        </>
    );
}
